import React from "react";

// Bootstrap imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import projects from "../../constants/project-summaries";

export default function Projects() {
  return (
    <div>
      <h1 className="home-projects-container">Featured Projects</h1>
      <a 
        href={`/projects`} 
        className="home-projects-container-link">
            View our newest cohort of projects (Fall 2024)
      </a>
      {projects.map((project) => {
        return (
          project.featured && 
            <Project
            key={project.title}
            title={project.title}
            text={project.text}
            image={project.image}
            route={project.route}
          />
        );
      })}
    </div>
  );
}

// Component for each project - pass in title, summary text and image
function Project({ title, text, image, route }) {

  return (
    <div className="home-project" id={route}>
      <Row>
        <Col xs={12} md={7}>
          <a href={`/projects/${route}`}>
            <h3>{title}</h3>
          </a>
          <p>{text}</p>
        </Col>
        <Col xs={12} md={5}>
          <img src={image} alt={title} loading="lazy" className="home-project-image" />
        </Col>
      </Row>
      <hr />
    </div>
  );
}
