import React, { useState, useEffect } from "react";

export default function ProjectPage({ title, authors, text, images }) {
  const [chunks, setChunks] = useState([]);

  // Split text into chunks based on the number of images (or a fallback)
  function splitTextIntoArray(textToBeSplit, numChunks) {
    let tempChunks = [];
    
    const splitText = textToBeSplit.toString().split("\n\n"); // Split by paragraph (double newline)
    
    // If there are more chunks than paragraphs, repeat the last chunk
    const chunkSize = Math.ceil(splitText.length / numChunks);
    for (let i = 0; i < splitText.length; i += chunkSize) {
      tempChunks.push(splitText.slice(i, i + chunkSize).join("\n\n"));
    }
    return tempChunks;
  }

  function createTextChunks() {
    const numChunks = images.length;
    let tempChunks = [];
    
    // If there are no images, treat the entire text as one chunk
    if (numChunks === 0) {
      tempChunks.push({ textChunk: text, picture: null, caption: null });
    } else {
      // Split text into chunks based on image count
      const splitText = splitTextIntoArray(text, numChunks);

      // Create pairs of text and images
      images.forEach((image, i) => {
        tempChunks.push({
          textChunk: splitText[i],
          picture: image.image,
          caption: image.caption,
        });
      });
    }

    setChunks(tempChunks);
  }

  useEffect(() => {
    document.title = title;
    createTextChunks();
  }, [title, createTextChunks]);

  return (
    <div className="project-page">
      <h1>{title}</h1>
      {authors.length > 0 && (
        <h5>
          Contributors:&nbsp;
          <div style={{ display: "inline-block" }}>
            {authors.map((author, index) => (
              <span key={index}>
                {author}
                {index < authors.length - 1 ? ", " : ""}
              </span>
            ))}
          </div>
        </h5>
      )}
      {chunks.map((chunk, i) => {
        const { textChunk, picture, caption } = chunk;
        return (
          <div key={i}>
            {picture && (
              <aside className={i % 2 === 0 ? "aside-right" : "aside-left"}>
                <figure>
                  <img
                    src={picture}
                    style={{
                      maxWidth: "600px",
                      width: "100%",
                      maxHeight: "300px",
                      height: "100%",
                    }}
                    alt={caption}
                    loading="lazy"
                  />
                  <figcaption>{caption}</figcaption>
                </figure>
              </aside>
            )}
            {/* Render each paragraph as a separate <p> tag */}
            {textChunk.split("\n\n").map((paragraph, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
            ))}
          </div>
        );
      })}
    </div>
  );
}